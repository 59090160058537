import { Icon } from "@justhome/react-ui";

const PreviewBanner = () => {
  return (
    <div className="fixed bottom-none right-none left-none m:left-auto py-2xs px-s flex bg-purple-775 m:rounded-tl-2xs justify-center items-center gap-2xs text-neutral-0 text-body-2 z-50">
      <Icon type="Eyes" size="16" />
      You are in preview mode -
      <a className="px-s underline" href={`/api/exit-preview`}>
        Turn off to safely browse the website again
      </a>
    </div>
  );
};

export default PreviewBanner;
