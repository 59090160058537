import Container from "@components/common/Container";
import Link from "@components/common/Link";
import { PoweredBy } from "@justhome/react-ui";
import useLocale from "@lib/hooks/useLocale";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { FC } from "react";

interface MinimalFooterProps {
  className?: string;
  isFullWidth?: boolean;
  isPartner?: boolean;
}

const MinimalFooter: FC<MinimalFooterProps> = ({
  className,
  isFullWidth,
  isPartner,
}) => {
  const locale = useLocale();
  const { t } = useTranslation();
  return (
    <Container fullWidth={isFullWidth} padding>
      <div
        className={clsx(
          "flex flex-col-reverse m:flex-row gap-2s pt-3s pb-3xs items-center justify-between text-body-2",
          className,
        )}
      >
        <span className="whitespace-nowrap">
          <span className="text-opacity-50 text-neutral-675">
            {t("copyright", { year: new Date().getFullYear() })}
          </span>
          <Link
            className="ml-2s underline text-opacity-50 text-neutral-675"
            href={t("imprint_href")}
          >
            {t("imprint_label")}
          </Link>
        </span>
        {isPartner && <PoweredBy locale={locale === "en" ? "en" : "de"} />}
      </div>
    </Container>
  );
};

export default MinimalFooter;
