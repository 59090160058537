import Footer from "@components/blocks/Footer";
import Container from "@components/common/Container";
import DynamicZone, {
  DynamicZoneComponents,
} from "@components/common/DynamicZone";
import MinimalFooter from "@components/common/MinimalFooter";
import NavBar from "@components/common/NavBar";
import PreviewBanner from "@components/common/PreviewBanner";
import SEO from "@components/common/SEO";
import { config } from "@lib/config";
import type {
  ComponentSectionsCustomerReviews,
  ComponentSectionsHeroThree,
  ComponentSectionsHeroV3,
  ComponentSectionsInterestRateChart,
  ComponentSectionsPartnerLeadTransition,
  ComponentSectionsPillarArticles,
  ComponentSharedSeo,
  Navigation,
  Page,
  PageContentSectionsDynamicZone,
  PageEntity,
} from "@lib/generated/graphql";
import { getCanonicalLink, getPagePath } from "@lib/routing";
import getNavigation from "@lib/strapi/getNavigation";
import getPageData from "@lib/strapi/getPageData";
import getPages from "@lib/strapi/getPages";
import { filterStrapiPages } from "@lib/strapi/utilities";
import getStaticData from "@modules/homepage/sections/CustomerReviews/getStaticData";
import getStaticInterestRateData from "@modules/homepage/sections/InterestRateChart/getStaticData";
import getStaticPillarArticlesData from "@modules/learning-center/sections/PillarArticles/getStaticData";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import dynamic from "next/dynamic";
import { Locale } from "next/router";

const ComponentSectionsAccordionV2 = dynamic<any>(
  () => import("@modules/homepage/sections/AnimatedAccordion"),
);
const ComponentSectionsArticleList = dynamic<any>(
  () => import("@modules/learning-center/sections/ArticleList"),
);
const ComponentSectionsArticleSearch = dynamic<any>(
  () => import("@modules/learning-center/sections/ArticleSearch"),
);
const ComponentSectionsArticleSearchBanner = dynamic<any>(
  () => import("@modules/learning-center/sections/ArticleSearchBanner"),
);
const ComponentSectionsBudgetCalculator = dynamic<any>(
  () => import("@modules/homepage/sections/BudgetCalculator"),
);
const ComponentSectionsCategoryCards = dynamic<any>(
  () => import("@modules/homepage/sections/CategoryCards"),
);
const ComponentSectionsContactUs = dynamic<any>(
  () => import("@modules/homepage/sections/ContactUs"),
);
const ComponentSectionsCtaCard = dynamic<any>(
  () => import("@modules/homepage/sections/CtaCard"),
);
const ComponentSectionsCtaCardWithRunningLine = dynamic<any>(
  () => import("@modules/homepage/sections/CtaCardWithRunningLine"),
);
const ComponentSectionsCtaWithFaqV2 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithAccordion"),
);
const ComponentSectionsCtaWithFeaturesV2 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithFeatures"),
);
const ComponentSectionsCtaWithImageV3 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithImageV3"),
);
const ComponentSectionsCtaWithTestimonials = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithTestimonials"),
);
const ComponentSectionsCtaWithTextV2 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithText"),
);
const ComponentSectionsCtaWithVideoV2 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithVideo"),
);
const ComponentSectionsCtaWithVideoV3 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithVideoV3"),
);
const ComponentSectionsFeaturedIn = dynamic<any>(
  () => import("@modules/homepage/sections/FeaturedIn"),
);
const ComponentSectionsFormAndCta = dynamic<any>(
  () => import("@modules/learning-center/sections/FormAndCta"),
);
const ComponentSectionsHeadline = dynamic<any>(
  () => import("@modules/homepage/sections/Headline"),
);
const ComponentSectionsHeroV2 = dynamic<any>(
  () => import("@modules/homepage/sections/Hero"),
);
const ComponentSectionsHeroSecondaryV2 = dynamic<any>(
  () => import("@modules/homepage/sections/HeroSecondary"),
);
const ComponentSectionsHeroTwo = dynamic<any>(
  () => import("@modules/homepage/sections/HeroTwo"),
);
const ComponentSectionsHeroV3Component = dynamic<any>(
  () => import("@modules/homepage/sections/HeroV3"),
);
const ComponentSectionsHeyflow = dynamic<any>(
  () => import("@modules/homepage/sections/Heyflow"),
);
const ComponentSectionsCustomerReviews = dynamic<any>(
  () => import("@modules/homepage/sections/CustomerReviews"),
);
const ComponentSectionsMediaOutlets = dynamic<any>(
  () => import("@modules/homepage/sections/MediaOutlets"),
);
const ComponentSectionsHorizontalRunningLinesV2 = dynamic<any>(
  () => import("@modules/homepage/sections/HorizontalRunningLines"),
);
const ComponentSectionsHorizontalScrollCarousel = dynamic<any>(
  () => import("@modules/homepage/sections/HorizontalScrollCarousel"),
);
const ComponentSectionsHubspotForm = dynamic<any>(
  () => import("@modules/homepage/sections/HubspotForm"),
);
const ComponentSectionsImageCarousel = dynamic<any>(
  () => import("@modules/homepage/sections/ImageCarousel"),
);
const ComponentSectionsJobsRunningLine = dynamic<any>(
  () => import("@modules/homepage/sections/JobsRunningLine"),
);
const ComponentSectionsOpenJobs = dynamic<any>(
  () => import("@modules/homepage/sections/OpenJobs"),
);
const ComponentSectionsPillarArticles = dynamic<any>(
  () => import("@modules/learning-center/sections/PillarArticles"),
);
const ComponentSectionsRichTextV2 = dynamic<any>(
  () => import("@modules/homepage/sections/RichTextV2"),
);
const ComponentSectionsTeamOfExpertsV2 = dynamic<any>(
  () => import("@modules/homepage/sections/TeamOfExperts"),
);
const ComponentSectionsTestimonialCards = dynamic<any>(
  () => import("@modules/homepage/sections/TestimonialCards"),
);
const ComponentSectionsTestimonialsV2 = dynamic<any>(
  () => import("@modules/homepage/sections/Testimonials"),
);
const ComponentSectionsVerticalRunningLinesV2 = dynamic<any>(
  () => import("@modules/homepage/sections/VerticalRunningLines"),
);
const ComponentSectionsWorkProcessV2 = dynamic<any>(
  () => import("@modules/homepage/sections/WorkProcess"),
);
const ComponentSectionsWorkProcessThreeV2 = dynamic<any>(
  () => import("@modules/homepage/sections/WorkProcessThree"),
);
const ComponentSectionsWorkProcessTwoV2 = dynamic<any>(
  () => import("@modules/homepage/sections/WorkProcessTwo"),
);
const ComponentSectionsAdvisorMeetings = dynamic<any>(
  () => import("@modules/homepage/sections/AdvisorMeeting"),
);

const ComponentSectionsArticleOverviewBanner = dynamic<any>(
  () => import("@modules/learning-center/sections/ArticleOverviewBanner"),
);
const ComponentSectionsCtaWithFaqV3 = dynamic<any>(
  () => import("@modules/homepage/sections/CtaWithFaqV3"),
);
const ComponentSectionsBudgetCheckForm = dynamic<any>(
  () => import("@modules/homepage/sections/BudgetCheckForm"),
);

const ComponentSectionsBudgetFormNavigator = dynamic<any>(
  () => import("@modules/application/components/BudgetFormNavigator"),
);

const ComponentSectionsInterestRateChart = dynamic<any>(
  () => import("@modules/homepage/sections/InterestRateChart"),
);

const ComponentSectionsPartnerLeadTransition = dynamic<any>(
  () => import("@modules/homepage/sections/PartnerLeadTransition"),
);

const dynamicZoneComponents: DynamicZoneComponents<PageContentSectionsDynamicZone> =
  {
    // HYGEN IMPORT MARKER
    ComponentSectionsRepaymentCalculator: dynamic(
      () => import("@modules/homepage/sections/RepaymentCalculator"),
    ),
    ComponentSectionsHeroThree: dynamic<any>(
      () => import("@modules/homepage/sections/HeroThree"),
    ),
    ComponentSectionsWorkProcessFour: dynamic<any>(
      () => import("@modules/homepage/sections/WorkProcessFour"),
    ),
    ComponentSectionsCta: dynamic(
      () => import("@modules/homepage/sections/Cta"),
    ),
    ComponentSectionsBudgetCheckForm,
    ComponentSectionsCtaWithVideoV3,
    ComponentSectionsHeyflow,
    ComponentSectionsCustomerReviews,
    ComponentSectionsMediaOutlets,
    ComponentSectionsPillarArticles,
    ComponentSectionsFormAndCta,
    ComponentSectionsArticleOverviewBanner,
    ComponentSectionsArticleSearch,
    ComponentSectionsTestimonialsV2,
    ComponentSectionsCtaWithVideoV2,
    ComponentSectionsAccordionV2,
    ComponentSectionsCtaWithFaqV2,
    ComponentSectionsCtaWithTextV2,
    ComponentSectionsCtaWithFeaturesV2,
    ComponentSectionsHeroV2,
    ComponentSectionsVerticalRunningLinesV2,
    ComponentSectionsHorizontalRunningLinesV2,
    ComponentSectionsHeroSecondaryV2,
    ComponentSectionsCtaCard,
    ComponentSectionsCtaWithTestimonials,
    ComponentSectionsCtaCardWithRunningLine,
    ComponentSectionsTeamOfExpertsV2,
    ComponentSectionsArticleList,
    ComponentSectionsWorkProcessTwoV2,
    ComponentSectionsWorkProcessThreeV2,
    ComponentSectionsHorizontalScrollCarousel,
    ComponentSectionsContactUs,
    ComponentSectionsTestimonialCards,
    ComponentSectionsImageCarousel,
    ComponentSectionsOpenJobs,
    ComponentSectionsJobsRunningLine,
    ComponentSectionsHeroTwo,
    ComponentSectionsWorkProcessV2,
    ComponentSectionsRichTextV2,
    ComponentSectionsCtaWithImageV3,
    ComponentSectionsBudgetCalculator,
    ComponentSectionsHeroV3: ComponentSectionsHeroV3Component,
    ComponentSectionsFeaturedIn,
    ComponentSectionsHeadline,
    ComponentSectionsHubspotForm,
    ComponentSectionsAdvisorMeetings,
    ComponentSectionsArticleSearchBanner,
    ComponentSectionsCategoryCards,
    ComponentSectionsCtaWithFaqV3,
    ComponentSectionsBudgetFormNavigator,
    ComponentSectionsInterestRateChart,
    ComponentSectionsPartnerLeadTransition,
    Error: null,
  };

const getPageSeoWithCanonicalLink = (
  page: Page,
  locale: Locale,
): ComponentSharedSeo | undefined | null => {
  if (page.seo && !page.seo.canonicalURL && page.slug) {
    const pagePath = getPagePath(page)!;
    const canonicalURL = getCanonicalLink(locale, pagePath);
    return { ...page.seo, canonicalURL };
  }
  return page.seo;
};

function CMSPage({
  page,
  sectionData,
  preview,
  navigation,
  isPartner,
  locale,
}: {
  page: Page;
  sectionData: any;
  preview: boolean;
  navigation: Navigation;
  isPartner: boolean;
  locale: Locale;
}) {
  const sections = page?.contentSections || [];

  const [hero, otherSections] = sections.reduce(
    (prev, section) => {
      if (
        section?.__typename === "ComponentSectionsHeroV3" ||
        section?.__typename === "ComponentSectionsHeroThree"
      ) {
        prev[0] = section;
      } else if (section) {
        prev[1].push(section);
      }
      return prev;
    },
    [undefined, []] as [
      ComponentSectionsHeroV3 | ComponentSectionsHeroThree | undefined,
      PageContentSectionsDynamicZone[],
    ],
  );

  const seo = getPageSeoWithCanonicalLink(page, locale);

  return (
    <>
      {seo && <SEO seoData={seo} />}

      {preview && <PreviewBanner />}
      <NavBar data={navigation} isMinimal={!!page.minimalNavigation} />
      <div className="border-b border-neutral-100" />
      {hero && (
        <DynamicZone
          component={hero}
          index={0}
          staticComponentData={sectionData}
          components={dynamicZoneComponents}
        />
      )}
      <Container className="min-h-[calc(100vh-174px)]">
        {otherSections.map((section, index) => (
          <DynamicZone
            index={hero ? index + 1 : index}
            component={section}
            key={index}
            staticComponentData={sectionData}
            components={dynamicZoneComponents}
          />
        ))}
      </Container>
      <Container>
        {isPartner ? (
          <MinimalFooter isPartner isFullWidth={false} />
        ) : (
          <Footer />
        )}
      </Container>
    </>
  );
}

export default CMSPage;

type Params = {
  previewData?: {
    version: string;
  };
  params: {
    slug: string;
  };
  locale: string;
  preview?: boolean;
};

export type StaticPathsProps = {
  locales: string[];
  defaultLocale: string;
};

export async function getStaticPaths(context: StaticPathsProps) {
  const pages = await Promise.all(
    context.locales.map(async (locale: string) => {
      const localePages = await getPages({ isCobranded: false, locale });
      if (!localePages?.pages?.data) return [];
      const strapiPages = (localePages.pages?.data as PageEntity[]).map(
        (data) => ({
          slug: data.attributes?.slug as string,
          locale,
        }),
      );
      return filterStrapiPages(strapiPages);
    }),
  );

  const paths = pages.flat().map((page) => {
    const { slug, locale } = page;
    const slugArray = !slug ? false : slug.split("/");
    return {
      params: { slug: slugArray },
      locale,
    };
  });

  return { paths, fallback: config.cms.fallback };
}

const findSection = <T extends PageContentSectionsDynamicZone>(
  page: Page,
  typename: string,
): T | undefined => {
  return page?.contentSections?.find(
    (section) => section?.__typename === typename,
  ) as T | undefined;
};

export async function getStaticProps({ params, locale, preview }: Params) {
  const slug = Array.isArray(params.slug) ? params.slug.join("/") : params.slug;
  const pageData = await getPageData({
    slug,
    locale,
    publicationState: preview ? "PREVIEW" : "LIVE",
  });

  if (!pageData?.attributes) {
    return {
      notFound: true,
    };
  }

  const page = pageData.attributes;

  const navigation = await getNavigation(locale);
  const sectionData: any = {};

  const pillarArticlesSection = findSection<ComponentSectionsPillarArticles>(
    page,
    "ComponentSectionsPillarArticles",
  );

  if (pillarArticlesSection) {
    sectionData.ComponentSectionsPillarArticles =
      await getStaticPillarArticlesData(pillarArticlesSection, locale);
  }

  const customerReviewsSection = findSection<ComponentSectionsCustomerReviews>(
    page,
    "ComponentSectionsCustomerReviews",
  );

  if (customerReviewsSection) {
    sectionData.ComponentSectionsCustomerReviews = await getStaticData();
  }

  const interestRateSection = findSection<ComponentSectionsInterestRateChart>(
    page,
    "ComponentSectionsInterestRateChart",
  );

  if (interestRateSection) {
    // short term solution until server components are available (app router)
    // filter data in client component
    const fullPeriodUnder10Years = await getStaticInterestRateData("60m");
    const fullPeriodOver10Years = await getStaticInterestRateData("180m");

    sectionData.ComponentSectionsInterestRateChart = {
      "60m": fullPeriodUnder10Years,
      "180m": fullPeriodOver10Years,
    };
  }

  return {
    props: {
      locale,
      ...(await serverSideTranslations(locale)),
      navigation,
      page,
      sectionData,
      preview: !!preview,
    },
    revalidate: config.cms.revalidate,
  };
}
