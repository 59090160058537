import clsx from "clsx";
import Image from "../internal/Image";
import { Locale } from "../lib/i18n";

interface PoweredByProps {
  locale?: Locale;
  className?: string;
}

const PoweredBy = ({ className, locale = "de" }: PoweredByProps) => {
  return (
    <div
      className={clsx(
        "flex justify-end items-center gap-2xs text-caption-1",
        className,
      )}
    >
      <span>{locale === "de" ? "Ein Service von" : "Powered by"}</span>
      <Image
        className="h-3s w-auto mb-[2px]"
        src="https://cdn.justhome.com/cdn/assets/logo_43e3df0153.svg"
        alt="Ein Service von Justhome"
        width="170"
        height="40"
      />
    </div>
  );
};

export default PoweredBy;
