import { FC } from "react";
import { getImageComponent } from "../../lib/config";

const Image: FC<{
  src: string;
  width: string;
  height: string;
  alt: string;
  className?: string;
}> = (props) => {
  const Component = getImageComponent();
  return <Component {...props} />;
};

export default Image;
