import { logger } from "@justhome/common";
import { Maybe } from "@lib/generated/graphql";

interface StrapiComponent {
  __typename?: Maybe<string>;
  staticData?: any;
}

type DynamicZoneTypename<T extends StrapiComponent> = Extract<
  T["__typename"],
  string
>;

export type DynamicZoneComponents<T extends StrapiComponent> = Record<
  DynamicZoneTypename<T>,
  any
>;

export type DynamicZoneStaticComponentData<T extends StrapiComponent> = Record<
  DynamicZoneTypename<T>,
  any
>;

const DynamicZone = <T extends StrapiComponent>({
  component,
  components,
  staticComponentData,
  index,
}: {
  component: Maybe<T>;
  components: DynamicZoneComponents<T>;
  staticComponentData?: DynamicZoneStaticComponentData<T>;
  index: number;
}) => {
  if (!component) return null;
  const typename = component.__typename as DynamicZoneTypename<T>;
  const Component = components[typename];
  const staticData = staticComponentData?.[typename];

  if (!Component) {
    logger.warn(`Unknown component ${component.__typename}.`);
    return null;
  }

  return <Component data={component} index={index} staticData={staticData} />;
};

export default DynamicZone;
