import { ComponentSharedSeo } from "@lib/generated/graphql";
import { NextSeo } from "next-seo";
import { OpenGraph } from "next-seo/lib/types";
import Head from "next/head";

const noIndexRegex = /noindex/;
const noFollowRegex = /nofollow/;

const SEO = ({
  seoData,
  openGraph,
}: {
  seoData: ComponentSharedSeo;
  openGraph?: OpenGraph;
}) => {
  const nofollow = seoData.metaRobots
    ? noFollowRegex.test(seoData.metaRobots)
    : false;
  const noindex = seoData.metaRobots
    ? noIndexRegex.test(seoData.metaRobots)
    : false;

  return (
    <>
      <NextSeo
        nofollow={nofollow}
        noindex={noindex}
        title={seoData.metaTitle}
        description={seoData.metaDescription}
        canonical={seoData.canonicalURL || ""}
        openGraph={openGraph}
      />
      <Head>
        <meta name="keywords" content={seoData.keywords || ""} />
      </Head>
    </>
  );
};

export default SEO;
